import * as React from 'react';
import { Dimensions, StyleSheet, View, Platform } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { translate, translateFirstUpper } from 'secullum-i18n';
import {
  Card,
  RadioGroup,
  RangeDatePicker,
  isTablet,
  Text
} from 'secullum-react-native-ui';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory-native';
import { IndicadoresDados } from '../../shared/modules/types';
import {
  convertDecimalHourToHour,
  converterDataParaTimezoneBrasil
} from '../../shared/modules/utils';
import { getTheme, isHighResolution } from '../modules/layout';

interface CardHorasPeriodoProps {
  positivo: boolean;
  titulo: string;
  visualizacoes: IndicadoresDados;
  dataInicial: Date;
  dataFinal: Date;
  nativeID?: string;
  onDataInicialChange: (date: Date) => void;
  onDataFinalChange: (date: Date) => void;
  onEndDateCancel: () => void;
}

interface State {
  tipoVisualizacao: string | null;
  width: number;
  limiteFiltro: number;
}

class CardHorasPeriodo extends React.Component<CardHorasPeriodoProps, State> {
  state: State = {
    tipoVisualizacao: null,
    width: Dimensions.get('window').width - 16 * 2,
    limiteFiltro: 11
  };

  componentDidMount() {
    const dimension = Dimensions.get('window').width;

    if (dimension < 360) {
      this.setState({ limiteFiltro: 7 });
    }
  }

  handleTipoVisualizacaoChange = (tipoVisualizacao: string) => {
    this.setState({ tipoVisualizacao });
  };

  handleOnLayout = () => {
    this.setState({ width: Dimensions.get('window').width - 16 * 2 });
  };

  buscarTipoVisualizacao = (): string => {
    const { limiteFiltro, tipoVisualizacao } = this.state;
    const { visualizacoes } = this.props;

    if (
      tipoVisualizacao !== null &&
      visualizacoes[tipoVisualizacao].valores.length < limiteFiltro
    ) {
      return tipoVisualizacao;
    }

    if (visualizacoes['dia'].valores.length < limiteFiltro) {
      return 'dia';
    }

    if (visualizacoes['semana'].valores.length < limiteFiltro) {
      return 'semana';
    }

    return 'mes';
  };

  render() {
    const { limiteFiltro, width } = this.state;
    const { visualizacoes, positivo, titulo, nativeID } = this.props;
    const tiposVisualizacoes: Array<{ label: string; value: string }> = [
      { label: translate('Dia'), value: 'dia' },
      { label: translate('Semana'), value: 'semana' },
      { label: translateFirstUpper('Mês'), value: 'mes' }
    ];

    const visualizacao = visualizacoes[this.buscarTipoVisualizacao()];
    const total = visualizacao.valores.reduce((acc, x) => acc + x.y, 0);
    const cor = positivo ? theme.successColor : theme.errorColor;
    const disabledList = tiposVisualizacoes
      .filter(x => visualizacoes[x.value].valores.length > limiteFiltro)
      .map(x => x.value);

    return (
      <Card
        style={[
          styles.cardHorasPeriodoMargin,
          Platform.OS === 'web' && styles.cardHorasPeriodo,
          { width: Platform.OS === 'web' && isHighResolution() ? 342 : '100%' }
        ]}
      >
        <Card.Header nativeID={nativeID} title={titulo} />
        <Card.Section style={styles.section}>
          <View style={styles.wrapperCampos}>
            <RadioGroup
              items={tiposVisualizacoes}
              value={this.buscarTipoVisualizacao()}
              onChange={this.handleTipoVisualizacaoChange}
              disabled={disabledList}
            />
          </View>
          {/* Case 136325: App Central do Funcionário fechando ao realizar filtro em indicadores
            O erro estava ocorrendo na lib externa VictoryChart, que não consegue renderizar um componente
            com valores zerados. Então definimos que a View responsável pelo gráfico só será renderizada
            se o valor total for maior que zero, para prevenir o crash no APP.
            Indicadores de Banco de Horas e os personalizados já possuem tratamento para o erro.*/}
          {total > 0 ? (
            <>
              <View style={styles.totalHorasContainer}>
                <FontAwesome name="clock-o" size={18} color={cor} />
                <Text
                  nativeID={`${nativeID}-total`}
                  bold
                  color={cor}
                  size={isTablet() ? 28 : 24}
                  style={styles.totalHorasValor}
                >
                  {visualizacoes.exibirHorasEmDecimal
                    ? total.toFixed(2).replace('.', ',')
                    : convertDecimalHourToHour(total)}
                </Text>
                <Text
                  bold
                  color={theme.textColor3}
                  size={isTablet() ? 16 : 12}
                  style={styles.totalHorasTexto}
                >
                  {titulo} {translate('no Período')}
                </Text>
              </View>
              <View
                onLayout={this.handleOnLayout}
                style={{ pointerEvents: 'none' }}
              >
                <VictoryChart
                  padding={
                    Platform.OS === 'web'
                      ? { top: 5, right: 15, bottom: 26, left: 45 }
                      : { top: 16, right: 25, bottom: 32, left: 40 }
                  }
                  height={Platform.OS === 'web' ? 146 : 200}
                  width={
                    Platform.OS === 'web' && isHighResolution() ? 342 : width
                  }
                  domainPadding={10}
                >
                  <VictoryAxis
                    style={{
                      axis: {
                        stroke: 'transparent'
                      },
                      tickLabels: {
                        fill: theme.textColor3,
                        fontSize: Platform.OS === 'web' ? 10 : 8,
                        //@ts-ignore
                        angle: 45
                      }
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    tickFormat={(datum: any) => convertDecimalHourToHour(datum)}
                    tickValues={total > 5 ? undefined : [0, 1, 2, 3, 4, 5]}
                    tickCount={8}
                    style={{
                      axis: {
                        stroke: 'transparent'
                      },
                      grid: {
                        stroke: theme.textColor3,
                        strokeWidth: 0.5
                      },
                      tickLabels: {
                        fill: theme.textColor3,
                        fontSize: Platform.OS === 'web' ? 12 : 8
                      }
                    }}
                  />
                  <VictoryBar
                    data={visualizacao.valores}
                    barWidth={8}
                    style={{
                      data: {
                        fill: cor
                      }
                    }}
                  />
                </VictoryChart>
              </View>
            </>
          ) : (
            <Text
              bold
              align={'center'}
              size={isTablet() ? 24 : 20}
              style={styles.mensagemGrafico}
            >
              {translate('Não há dados suficientes para montar o gráfico')}
            </Text>
          )}

          <View style={styles.wrapperCampos}>
            <RangeDatePicker
              nativeID={`${nativeID}-data`}
              label={translate('Insira o Período')}
              startDate={converterDataParaTimezoneBrasil(
                this.props.dataInicial
              )}
              endDate={converterDataParaTimezoneBrasil(this.props.dataFinal)}
              onStartDateChange={this.props.onDataInicialChange}
              onEndDateChange={this.props.onDataFinalChange}
              onEndDateCancel={this.props.onEndDateCancel}
            />
          </View>
        </Card.Section>
      </Card>
    );
  }
}

const theme = getTheme();

const styles = StyleSheet.create({
  cardHorasPeriodoMargin: {
    marginTop: 16
  },
  cardHorasPeriodo: {
    height: 342,
    marginRight: 16
  },
  section: {
    padding: 0
  },
  wrapperCampos: {
    padding: Platform.OS === 'web' ? 8 : isTablet() ? 20 : 16
  },
  totalHorasContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: isTablet() ? 20 : 16
  },
  totalHorasValor: {
    lineHeight: isTablet() ? 28 : 24,
    marginHorizontal: isTablet() ? 10 : 5
  },
  totalHorasTexto: {
    lineHeight: isTablet() ? 20 : 16
  },
  mensagemGrafico: {
    paddingVertical: 5,
    paddingHorizontal: 16,
    height: Platform.OS === 'web' ? 170 : undefined,
    display: Platform.OS === 'web' ? 'flex' : undefined,
    alignItems: Platform.OS === 'web' ? 'center' : undefined
  }
});

export default CardHorasPeriodo;
